// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import { GetApi } from "../../ApIcall";

// const data = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }];

// function SampleNextArrow(props) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       {/* <img src="assets/home/round-right.svg" alt="" /> */}
//       <img src="assets/home/s-right.svg" alt="" />
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       {/* <img src="assets/home/round-left.svg" alt="" /> */}
//       <img src="assets/home/s-left.svg" alt="" />
//     </div>
//   );
// }

// function StoriesSlider() {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const settings = {
//     dots: false,
//     infinite: true,
//     slidesToShow: 2,
//     slidesToScroll: 1,
//     nextArrow: <SampleNextArrow />,
//     prevArrow: <SamplePrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   const get_all_testimonials = async () => {
//     try {
//       const response = await GetApi('api/admin/getAllTestimonial');
//       setData(response?.data?.data || []);
//       setLoading(false);
//       console.log(response.data.data)
//     } catch (error) {
//       console.error('Error fetching testimonials:', error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     get_all_testimonials();
//   }, []);

//   // if (loading) {
//   //   return <div>Loading...</div>;
//   // }

//   // const [rating, setRating] = useState(0);
//   // const [hover, setHover] = useState(0);

//   return (
//     <>
//       <Slider {...settings}>
//         {data?.map((e, i) => {
//           return (
//             <>
              
//               <div key={i} className="pb-[32px]">
//                 <div className="max-w-[605px] bg-[#FFFFFF] rounded-[9.72px p-[18px] m-auto w-[95%] flex md:flex-row flex-col gap-[26px]">
//                   {/* <img src="assets/home/photo6.svg" alt="" /> */}
//                   <div className="pt-[7px] w-full">
//                     <img src="/assets/home/icon11.svg" alt="" />
//                     <p className="pt-[6px] font-[Outfit] font-[400] text-[18px] leading-[26px] text-[#454545]">
//                       Happy handwritten thank you notes… I just wanted to let
//                       you know that it's been great working with you.
//                     </p>
//                     <div className="flex justify-end">
//                       <img src="/assets/home/icon12.svg" alt="" />
//                     </div>
//                     <div className="md:pt-[52px] pt-[16px]">
//                       <p className="font-[Roboto] font-[600] text-[14px] leading-[16.41px] text-[#D0021B] pb-[3px]">
//                         Aman Singh Kushwah
//                       </p>
//                       <p className="font-[Roboto] font-[400] text-[14px] leading-[20px] text-[#454545] pb-[8px]">
//                         SSC CGL
//                       </p>
//                       <div className="flex gap-[4.30px]">
//                         <img src="assets/home/star.svg" alt="" />
//                         <img src="assets/home/star.svg" alt="" />
//                         <img src="assets/home/star.svg" alt="" />
//                         <img src="assets/home/star.svg" alt="" />
//                         <img src="assets/home/star.svg" alt="" />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </>
//           );
//         })}
//       </Slider>
//     </>
//   );
// }

// export default StoriesSlider;
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { GetApi } from "../../ApIcall";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/s-right.svg" alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/s-left.svg" alt="" />
    </div>
  );
}

function StoriesSlider() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const get_all_testimonials = async () => {
    try {
      const response = await GetApi('api/admin/getAllTestimonial');
      setData(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    get_all_testimonials();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Slider {...settings}>
        {data.map((testimonial, index) => (
          <div key={index} className="pb-[32px]">
            <div className="max-w-[605px] bg-[#FFFFFF] rounded-[9.72px] p-[18px] m-auto w-[95%] flex md:flex-row flex-col gap-[26px]">
              <div className="pt-[7px] w-full">
                <img src="/assets/home/icon11.svg" alt="" />
                <p className="pt-[6px] font-[Outfit] font-[400] text-[18px] leading-[26px] text-[#454545]">
                  {testimonial?.Discription}
                  {console.log(testimonial?.Discription)}
                </p>
                <div className="flex justify-end">
                  <img src="/assets/home/icon12.svg" alt="" />
                </div>
                <div className="md:pt-[52px] pt-[16px]">
                  <p className="font-[Roboto] font-[600] text-[14px] leading-[16.41px] text-[#D0021B] pb-[3px]">
                    {testimonial?.Name}
                    {console.log(testimonial?.Name)}
                  </p>
                  <p className="font-[Roboto] font-[400] text-[14px] leading-[20px] text-[#454545] pb-[8px]">
                    {testimonial?.Post}
                    {console.log(testimonial?.Post)}
                  </p>
                                       <div className="flex gap-[4.30px]">
                                          <img src="assets/home/star.svg" alt="" /> 
                                          <img src="assets/home/star.svg" alt="" />
                                         <img src="assets/home/star.svg" alt="" />
                                         <img src="assets/home/star.svg" alt="" />
                                          <img src="assets/home/star.svg" alt="" />
                                       </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default StoriesSlider;
