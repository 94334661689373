export const courses = [
  // {
  //   courseName: "MPPSC",
  //   link: "/courses/mppsc",
  // },
  {
    courseName: "MPPSC",
    link: "/exam",
  },
  {
    courseName: "SSC",
    link: "/ssc-cgl",
  },
  {
    courseName: "BANKING",
    link: "/ibps-po",
  },
  {
    courseName: "RAILWAY",
    link: "/railway-alp",
  },
  {
    courseName: "MP ESB",
    link: "/mp-jail",
  },
  {
    courseName: "DEFENCE",
    link: "/",
  },
  {
    courseName: "TET Exams",
    link: "/",
  },
];
export const SSCcourses = [
  {
    courseName: "SSC CGL",
    link: "/ssc-cgl",
  },
  {
    courseName: "SSC CHSL",
    link: "/ssc-chsl",
  },
  {
    courseName: "SSC MTS",
    link: "/ssc-mts",
  },
  {
    courseName: "SSC CPO",
    link: "/ssc-cpo",
  },
  {
    courseName: "SSC GD",
    link: "/ssc-gd",
  },
];
export const BankCourseslink = [
  {
    courseName: "IBPS PO & SBI PO",
    link: "/ibps-po",
  },
  {
    courseName: "IBPS RRB's PO & Clerk",
    link: "/ibps-sbi",
  },
  {
    courseName: "RBI Assistant",
    link: "/ibps-rbi",
  },
  {
    courseName: "IBPS Clerk & SBI Clerk",
    link: "/ibps-clerk",
  },
];
export const MPESBcourses = [
  {
    courseName: "MP Jail Prahari",
    link: "/mp-jail",
  },
  {
    courseName: "MP Mahila Supervisor",
    link: "/mp-mahila",
  },
  {
    courseName: "MP Patwari",
    link: "/mp-patwari",
  },
  {
    courseName: "MP Police Constable",
    link: "/mp-police",
  },
  {
    courseName: "MP Sub Inspector",
    link: "/mp-sub-inspector",
  },
];
export const RailwayCourse = [
  {
    courseName: "Railway ALP",
    link: "/railway-alp",
  },
  {
    courseName: "RRB Technician",
    link: "/railway-technician",
  },
  {
    courseName: "Railway NTPC",
    link: "/railway-ntpc",
  },
  {
    courseName: "Railway Group-D",
    link: "/railway-group-d",
  },
  {
    courseName: "Railway RPF",
    link: "/railway-rpf",
  },
];
