import React, { useState, useRef } from "react";

function Vacancies({ Notification }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSyllabus, setCurrentSyllabus] = useState("");
  const scrollRef = useRef(null); // useRef for the scrollable container

  const handleItemClick = (syllabusContent) => {
    setCurrentSyllabus(syllabusContent);
    setIsModalOpen(true);
  };

  // Function to scroll right
  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  // Function to scroll left
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  return (
    <>
      {Notification?.length === 0 ? (
        <p className="text-center pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
          Will share the results for the recent exams soon.
        </p>
      ) : (
        <div className="relative">
         
          <div
            ref={scrollRef}
            className="flex flex-row md:flex-wrap overflow-x-auto space-x-4 md:space-x-0 no-scrollbar " scrollbar
            style={{ scrollBehavior: "smooth" ,margin :"auto" , display : 'flex' ,justifyContent : 'center'}}
          >
            {Notification?.map((e, i) => (
              <div
                style={{
                  marginBottom: "8px",
                }}
                key={i}
                className="flex-shrink-0"
              >
                <div className="border overflow-hidden m-[4px] h-full cursor-pointer md:max-w-[218px] max-w-[300px] w-full rounded-[8px] border-[#D2D0D0] p-[6px]">
                  <div onClick={() => handleItemClick(e?.Syllabus)}>
                    {!e?.Img ? (
                      <img
                        src="assets/about/photo4.png"
                        className="w-full object-cover"
                        alt=""
                       
                      />
                    ) : (
                      <img
                        src={e?.Img}
                        className="w-full rounded-[10px] object-cover"
                          alt=""
                         
                      />
                    )}
                    <div className="pt-[30px]">
                      <div data-aos="fade-left">
                        <p className="text-center md:h-[80px] h-auto pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
                          {e?.Name.length > 40
                            ? `${e?.Name.substring(0, 40)}...`
                            : e?.Name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
              
            </div>
            
        </div>
      )}

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div dangerouslySetInnerHTML={{ __html: currentSyllabus }} />
      </Modal>
    </>
  );
}

export default Vacancies;

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Details</h2>
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={onClose}
          >
            ×
          </button>
        </div>
        <div className="overflow-y-auto max-h-96">{children}</div>
      </div>
    </div>
  );
}
