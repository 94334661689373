import Aos from "aos";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../App.css';
import ExamButtons from "../components/buttons/ExamButtons";
import Footer from "../components/footer/Footer";
import Header from "../components/nav/Header";
import { Api_Url } from "../URL";

const data = [
  {
    id: 1,
    section: "General Intelligence and Reasoning",
    module1: "Module-I",
    module2: "Module-II",
    subject1: "Mathematical Abilities",
    subject2: "Reasoning and General Intelligence",
    qut1: "25",
    qut2: "50",
    mark1: "30 × 3 = 90",
    mark2: "30 × 3 = 90",
    time: "60 minutes",
  },
  {
    id: 2,
    section: "General Awareness",
    module1: "Module-I",
    module2: "Module-II",
    subject1: "English Language and Comprehension",
    subject2: "General Awareness",
    qut1: "25",
    qut2: "50",
    mark1: "45 × 3 = 145",
    mark2: "25 × 3 = 75",
    time: "60 minutes",
  },
  {
    id: 3,
    section: "Quantitative Aptitube",
    module1: "Module-I",
    module2: "Module-II",
    subject1: "Computer Knowledge Test",
    subject2: "Data Entry Speed Test ",
    qut1: "25",
    qut2: "50",
    mark1: "20 × 3 = 60",
    mark2: "",
    time: "15 minutes",
  },
  {
    id: 4,
    section: "English Comprehension",
    module1: "Module-I",
    module2: "Module-II",
    subject1: "Computer Knowledge Test",
    subject2: "Data Entry Speed Test ",
    qut1: "25",
    qut2: "50",
    mark1: "20 × 3 = 60",
    mark2: "",
    time: "15 minutes",
  },
];
const data1 = [
  {
    paper: "Paper II (JSO Post)",
    section: "Statistics",
    question: "100",
    marks: "100 × 2 = 200",
    duration: "2 hours",
  },
  {
    paper: "Paper III (AAO Post)",
    section: "General Studies (Finance and Economics)",
    question: "100",
    marks: "100 × 2 = 200",
    duration: "2 hours",
  },
];

function ExamCenter() {


  const [sscCGL, setSSCCGL] = useState();
  const [loading, setLoading] = useState(true);

  
  const getSSCExamLinks = async () => {
    try {
      const response = await axios.get(`${Api_Url}/api/admin/getSingleExamLink/SSC-CGL`);
      setSSCCGL(response?.data);
      console.log(response?.data)
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSSCExamLinks();
  }, []);

  function handleDownload() { 
    const pdfUrl = "/assets/pdf/SSCCGL.docx";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "SSC CGL.docx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <Header />
      <div className="mt-[82px] relative">
        <img
          src="assets/video/photo1.png"
          className="w-full md:h-auto h-[150px] object-cover"
          alt=""
        />
        <div className="container m-auto">
          <div className="absolute flex items-center flex-col top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div data-aos="fade-up">
              <p className="font-sans font-[700] text-[24px] leading-[24px] text-center text-[#000000]">
                Exam
              </p>
              <h6 className="font-sans font-[700] pb-[10px] text-[36px] leading-[36.97px] text-center text-[#D0021B]">
                SSC
              </h6>
              <img src="assets/video/line.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fffcf9] relative">
        <div className="container m-auto">
          <div className="max-w-[984px] relative md:h-[500px] w-full left-[50%] translate-x-[-50%] md:top-[-72px] top-0">
            <ExamButtons />
            <div className="rounded-[30px] md:pl-[54px] pl-[20px] md:pr-[43px] pr-[20px] pt-[35px] md:pb-0 pb-[30px] w-full md:absolute relative md:top-[90px] top-0 bg-[#D0021B]">
              <div className="flex justify-between md:flex-row flex-col items-center gap-[32px]">
                <div>
                  <h6 className="font-sans font-[700] text-[32px] leading-[43.65px] text-[#FFFFFF] pb-[20px]">
                    SSC
                  </h6>
                  <p className="font-[Outfit] font-[600] text-[16px] leading-[22px] text-[#FFFFFF]">
                    To target any examination, it is very crucial to know its
                    syllabus well. Candidates can plan their studies in the
                    right direction with accurate and smart strategy only after
                    having the detailed and correct information about the
                    syllabus. The SSC CGL Syllabus and Exam Pattern is set by
                    the staff selection commission. Let’s have a brief view to
                    the pattern of SSC CGL Tier-1 exams. The Tier-1 Exam is
                    qualifying in nature. It consists of 4 sections –
                    Quantitative Aptitude, General Awareness, English Language,
                    General Intelligence and Reasoning. All 4 sections have 25
                    questions each. For each incorrect answer there is a penalty
                    (negative marking) of 0.50 marks. Each question is of 2
                    marks which means the complete paper is a total of 200
                    marks. The time duration for the tier–1 exam is 60 minutes
                    (1 hour)
                  </p>
                  <div className="bg-[#FFFFFF] h-[6px] rounded-[48px] mt-[24px] mb-[45px] w-full"></div>
                </div>
                <img
                  src="assets/video/photo2.svg"
                  className="pt-[18px]"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#fffcf9] md:px-[100px] px-[20px] overflow-x-auto">
        <div className="container m-auto">
          <div data-aos="fade-up">
            <h6 className="bg-[#1C2672] text-[#FFFFFF] px-[22px] py-[10px] border-[0.81px] border-[#000000] rounded-t-[24px] font-sans font-[700] text-[20px] leading-[35px]">
              SSC CGL Tier 1 Exam Pattern 2024
            </h6>
          </div>
          <div data-aos="fade-up">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="text-xs bg-[#F1F2F6] mb-[12px] text-[#000000]">
                <tr>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] p-[19px]">
                    SNo.
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Sections
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    No. of Questions
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Marks
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Time Allotted
                  </th>
                </tr>
              </thead>
              <tbody className="pt-[10px]">
                {data?.map((e, i) => {
                  return (
                    <>
                      <tr
                        key={i}
                        className="bg-white border rounded-[4px] border-[#D2D0D0]"
                      >
                        <th className="pl-[22px] py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          {i + 1}
                        </th>
                        <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          {e?.section}
                        </td>
                        <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          {e?.qut1}
                        </td>
                        <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          {e?.qut2}
                        </td>
                        {

                          i == 0 && 
                          
                        <td  
                              rowSpan="4"
                              style={{
                                paddingLeft: "20px",
                              
                                // display: 'flex',
                                // justifyContent: 'center',
                                // alignItems : "center",
                                position : 'relative',
                                top : "0"
                              }}
                              
                           
                        
                              className="font-[Outfit] font-[400] text-[18px] leading-[22px]"
                            >
                              A cumulative
                              <br />    time of
                              <br />    60 minutes
                              <br/>(1 hour)
                        </td>
                        }

                      </tr>
                    </>
                  );
                })}
              </tbody>
              <tr className="bg-[#D0021B] border rounded-[4px] border-[#D9D9D9]">
                <th className="pl-[22px] py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
                  Total
                </th>
                <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]"></td>
                <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
                  100
                </td>
                <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
                  200
                </td>
                <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="bg-[#fffcf9] overflow-hidden md:px-[100px] px-[20px] md:pt-[156px] pt-[50px] md:pb-[110px] pb-[50px]">
        <div className="container m-auto">
          <div data-aos="fade-up">
            <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[634px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              SSC CGL Tier 1 Syllabus for General Intelligence and Reasoning
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid md:grid-cols-4 grid-cols-1 md:gap-0 gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px]">
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  1. Analogies
                </p>

                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  2. Similarities and differences
                </p>

                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  3. Space visualization
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  4. Spatial orientation
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  5. Problem-solving
                </p>
              </div>
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  6. Analysis
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  7. Judgment
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  8. Blood Relations
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  9. Decision making
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  10. Visual memory
                </p>
              </div>
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  11. Discrimination
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  12. Observation
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  13. Relationship concepts
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  14. Arithmetical reasoning
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  15. Figural classification
                </p>
              </div>
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  16. Arithmetic number series
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  17. Non-verbal series
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  18. Coding and decoding
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  19. Statement conclusion
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  20. Syllogistic reasoning
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fffcf9] overflow-hidden md:px-[100px] px-[20px] ">
        <div className="container m-auto grid md:grid-cols-2 grid-cols-1 gap-[31px]">
          <div data-aos="fade-right">
            <div>
              <h6 className="bg-[#D0021B] mb-[16px] max-w-[500px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
                SSC CGL Tier – 1 Syllabus for General Awareness
              </h6>
              <div className="bg-[#FFFFFF] rounded-[4px] md:mb-[110px] mb-[30px] border border-[#D2D0D0] py-[30px] px-[28px]">
                <div className="flex flex-col gap-[20px]">
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    1. India and its neighbouring countries especially
                    pertaining to History, Culture, Geography, Economic Scene,
                    General Policy & Scientific Research
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    2. Science
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    3. Current Affairs
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    4. Books and Authors
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    5. Sports
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    6. Important Schemes
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    7. Important Days
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    8. Portfolio
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    9. People in News
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    10. Static GK
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-left">
            <div>
              <h6 className="bg-[#D0021B] mb-[16px] max-w-[469px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
                SSC CGL Tier 1 Syllabus for English Language
              </h6>
              <div className="bg-[#FFFFFF] rounded-[4px] md:mb-[110px] mb-[30px] border border-[#D2D0D0] py-[30px] px-[28px]">
                <div className="flex flex-col gap-[20px]">
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    1. Idioms and Phrases
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    2. One word Substitution
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    3. Error Spotting
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    4. Fill in the Blanks
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    5. Spelling Error
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    6. Reading Comprehension
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    7. Synonyms-Antonyms
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    8. Active Passive
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    9. Sentence Rearrangement
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    10. Sentence Improvement
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    11. Cloze test
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fffcf9] overflow-hidden md:px-[100px] px-[20px] md:pb-[110px] pb-[50px]">
        <div className="container m-auto">
          <div data-aos="fade-up">
            <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[506px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              SSC CGL Tier 1 Syllabus for Quantitative Aptitude
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid md:grid-cols-4 grid-cols-1 md:gap-[24px] gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] md:px-[28px] px-[20px]">
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  1. Computation of whole numbers
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  2. Decimals
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  3. Fractions
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  4. Relationships between numbers
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  5. Profit and Loss
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  6. Discount
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  7. Partnership Business
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  8. Mixture and Alligation
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  9. Time and distance
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  10. Time & Work
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  11. Percentage
                </p>
              </div>
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  12. Ratio & Proportion
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  13. Square roots
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  14. Averages
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  15. Interest
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  16. Basic algebraic identities of School Algebra & Elementary
                  surds
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  17. Graphs of Linear Equations
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  18. Triangle and its various kinds of centres
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  19. Congruence and similarity of triangles
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  20. Circle and its chords, tangents, angles subtended by
                  chords of a circle, common tangents to two or more circles
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  21. Triangle
                </p>
              </div>
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  21. Triangle
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  22. Quadrilaterals
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  23. Regular Polygons
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  24. Right Prism
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  25. Right Circular Cone
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  26. Right Circular Cylinder
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  27. Sphere
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  28. Heights and Distances
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  29. Histogram
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  30. Frequency polygon
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  31. Bar diagram & Pie chart
                </p>
              </div>
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  32. Hemispheres
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  33. Rectangular Parallelepiped
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  34. Regular Right Pyramid with triangular or square base
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  35. Trigonometric ratio
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  36. Degree and Radian Measures
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  37. Standard Identities
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  38. Complementary angles
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fffcf9] overflow-hidden md:px-[100px] px-[20px] pb-[20px]">
        <div className="container m-auto">
          <div data-aos="fade-up">
            <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[506px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              SSC CGL 2024 Exam Pattern & Syllabus for Tier 2
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px] gap-[24px]">
              <div className="flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  The tier-2 exam has three papers. These papers are
                  post-specific.
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  1. Paper I : Compulsory for all posts (2 hours 30 minutes)
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  1. Paper I : Compulsory for all posts (2 hours 30 minutes)
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  2. Paper II: for candidates who apply for the posts of Junior
                  Statistical Officer (JSO) in the Ministry of Statistics and
                  Programme Implementation. (2 hours)
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  3. Paper III: for candidates who apply for the posts of
                  Assistant Audit Officer/ Assistant Accounts Officer. (2 hours)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <div className="bg-[#FFFcf9] overflow-y-hidden md:px-[100px] px-[20px] overflow-x-auto">
          <div className="container m-auto">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="text-xs bg-[#F1F2F6] mb-[12px] text-[#000000]">
                <tr>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] p-[19px]">
                    Sections
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Module
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Subject
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    No. of Questions
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Marks
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="pt-[10px]">
                {data?.map((e, i) => {
                  return (
                    <>
                      <tr
                        key={i}
                        className="bg-white border rounded-[4px] border-[#D2D0D0]"
                      >
                        <th className="pl-[22px] py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          {e?.section}
                        </th>
                        <th className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          <p className="font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.module1}
                          </p>
                          <p className="font-[Outfit] mt-[30px] font-[400] text-[18px] leading-[22px]">
                            {e?.module2}
                          </p>
                        </th>
                        <th className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          <p className="font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.subject1}
                          </p>
                          <p className="font-[Outfit] mt-[30px] font-[400] text-[18px] leading-[22px]">
                            {e?.subject2}
                          </p>
                        </th>
                        <th className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          <p className="font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.qut1}
                          </p>
                          <p className="font-[Outfit] mt-[30px] font-[400] text-[18px] leading-[22px]">
                            {e?.qut2}
                          </p>
                        </th>
                        <td className="py-[20px] flex flex-col gap-[30px]">
                          <p className="font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.mark1}
                          </p>
                          {e?.mark2 && (
                            <p className="font-[Outfit] font-[400] text-[18px] leading-[22px]">
                              {e?.mark2}
                            </p>
                          )}
                        </td>

                        <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                          {e?.time}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
              {/* <tr className="bg-[#D0021B] border rounded-[4px] border-[#D9D9D9]">
            <th className="pl-[22px] py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
              Total
            </th>
            <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]"></td>
            <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
              100
            </td>
            <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
              200
            </td>
            <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]"></td>
          </tr> */}
            </table>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <div className="bg-[#FFFcf9] overflow-y-hidden md:px-[100px] px-[20px]">
          <div className="container m-auto pt-[40px]">
            <p className="px-[28px] py-[30px] border border-[#D2D0D0] bg-[#FFFFFF] rounded-[4px] font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
              It is mandatory for the candidates to qualify all the sections of
              Paper-I. There is negative marking of 1 mark for each wrong answer
              in Section-I, Section-II and Module-I of Section-III of Paper-I
              and of 0.50 marks for each wrong answer in Paper-II and Paper-III.
            </p>
            <div className="relative overflow-x-auto mt-[20px]">
              <h6 className="bg-[#1C2672] text-[#FFFFFF] px-[22px] py-[10px] border-[0.81px] border-[#000000] rounded-t-[24px] font-sans font-[700] text-[20px] leading-[35px]">
                SSC CGL Tier 2 Paper 2 & 3
              </h6>
              <table className="w-full text-sm text-left rtl:text-right">
                <thead className="text-xs bg-[#F1F2F6] mb-[12px] text-[#000000]">
                  <tr>
                    <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] p-[19px]">
                      Paper
                    </th>
                    <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                      Section
                    </th>
                    <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                      No. of question
                    </th>
                    <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                      Maximum Marks
                    </th>
                    <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                      Duration
                    </th>
                  </tr>
                </thead>
                <tbody className="pt-[10px]">
                  {data1?.map((e, i) => {
                    return (
                      <>
                        <tr
                          key={i}
                          className="bg-white border rounded-[4px] border-[#D2D0D0]"
                        >
                          <th className="pl-[22px] py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.paper}
                          </th>
                          <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.section}
                          </td>
                          <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.question}
                          </td>
                          <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.marks}
                          </td>
                          <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                            {e?.duration}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#fffcf9] overflow-hidden md:px-[100px] px-[20px] md:pt-[110px] pt-[50px]">
        <div className="container m-auto">
          <div data-aos="fade-up">
            <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[564px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              Module-I of Session-I of Paper-I (Mathematical Abilities):
            </h6>
            <div className="bg-[#FFFFFF] rounded-[4px] mb-[20px] border border-[#D2D0D0] py-[30px] px-[28px] gap-[24px]">
              <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                This Section is similar to the first exam. However, the level
                and type of questions will be slightly more difficult than in
                the Tier 1 exam. The following are the important topics of the
                SSC CGL Syllabus of Module-I in Tier 2 exam.
              </p>
            </div>
          </div>
          <div data-aos="fade-up">
            <div className="flex flex-col gap-[12px]">
              <div className="grid grid-cols-12 bg-[#F1F2F6] p-[19px]">
                <div className="md:col-span-3 col-span-4">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Topics
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8">
                  <p className="font-sans md:pl-[77px] pl-[10px] font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Sub-topics
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Paper II (JSO Post)
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Computation of Whole Number
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Decimal and Fractions
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Relationship between numbers
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Fundamental arithmetical operations
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Percentages
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Ratio and Proportion
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Square roots
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Averages
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Interest (Simple and Compound)
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Profit and Loss
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Discount
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Partnership Business
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Mixture and Alligation
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Time and distance
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Time and work
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Algebra
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Basic algebraic identities of School Algebra and Elementary
                    surds (simple problems)
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Graphs of Linear Equations
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Geometry
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Similarity with elementary geometric figures and facts:
                    Triangle and its various kinds of centres
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Congruence and similarity of triangles
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Circle and its chords, tangents, angles subtended by chords
                    of a circle, common tangents to two or more circles.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Mensuration
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[20px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Triangle
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Quadrilaterals
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Regular Polygons
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Circle
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Right Prism
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Right Circular Cone
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Right Circular Cylinder
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Sphere
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Hemispheres
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Rectangular Parallelepiped
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Regular Right Pyramid with triangular or square Base.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Trigonometry
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Trigonometry
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Trigonometric ratios
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Complementary angles
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Height and distances (simple problems only)
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Standard Identities
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Statistics and probability
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Use of Tables and Graphs: Histogram, Frequency polygon,
                    Bar-diagram, Pie-chart
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Measures of central tendency: mean, median, mode, standard
                    deviation
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Calculation of simple probabilities
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 md:pt-[110px] pt-[40px] gap-[31px]">
            <div data-aos="fade-right">
              <div>
                <h6 className="bg-[#D0021B] mb-[16px] max-w-[500px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
                  Module-II of Section-I of Paper-I (Reasoning and General
                  Intelligence):
                </h6>
                <div className="bg-[#FFFFFF] rounded-[4px] md:mb-[110px] mb-[30px] border border-[#D2D0D0] py-[30px] px-[28px]">
                  <div className="flex flex-col gap-[20px]">
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      1. Semantic Analogy
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      2. Symbolic operations, Symbolic/ Number Analogy, Trends
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      3. Figural Analogy
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      4. Space Orientation
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      5. Semantic Classification
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      6. Venn Diagrams
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      7. Symbolic/ Number
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      8. Classification
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      9. Drawing inferences
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      10. Figural Classification
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      11. Punched hole/ pattern-folding & unfolding
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      12. Semantic Series
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      13. Figural Pattern folding and completion
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      14. Number Series
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      15. Embedded figures
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      16. Figural Series
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      17. Critical Thinking
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      18. Problem-Solving
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      19. Emotional Intelligence
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      20. Word Building
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      21. Social Intelligence
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      22. Coding and de-coding
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      23. Numerical operations
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      24. Other sub-topics, if any.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div data-aos="fade-left">
                <h6 className="bg-[#D0021B] mb-[16px] max-w-[469px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
                  Module-II of Section-I of Paper-I (Reasoning and General
                  Intelligence):
                </h6>
                <div className="bg-[#FFFFFF] rounded-[4px] md:mb-[110px] mb-[30px] border border-[#D2D0D0] py-[30px] px-[28px]">
                  <div className="flex flex-col gap-[20px]">
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      1. Vocabulary
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      2. English Grammar
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      3. Sentence structure
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      4. Spot the Error
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      5. Fill in the Blanks,
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      6. Synonyms/Homonyms
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      7. Antonyms
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      8. Spellings/ Detecting misspelt words
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      9. Idioms & Phrases
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      10. One word substitution
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      11. Improvement of Sentences,
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      12. Active/ Passive Voice of Verbs
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      13. Conversion into Direct/ Indirect narration
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      14. Shuffling of Sentence parts
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      15. Shuffling of Sentences in a passage,
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      16. Cloze Passage
                    </p>
                    <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                      17. Comprehension Passage
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#fffcf9] pb-[20px]">
            <div data-aos="fade-right">
              <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[564px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
                Module-II of Section-II of Paper-I (General Awareness):
              </h6>
            </div>
            <div data-aos="fade-up">
              <div className="bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px] gap-[24px]">
                <div className="flex flex-col gap-[20px]">
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    1. India and its neighbouring countries especially
                    pertaining to History, Culture, Geography, Economic Scene,
                    General Policy & Scientific Research
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    2. Science
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    3. Current Affairs
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    4. Books and Authors
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    5. Sports
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    6. Important Schemes
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    7. Important Days & Dates
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    8. Portfolio
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    9. People in News
                  </p>
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    10. Module-I of Section-III of Paper-I (Computer
                    Proficiency):
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up">
            <div className="flex flex-col gap-[12px]">
              <div className="grid grid-cols-12 bg-[#F1F2F6] p-[19px]">
                <div className="md:col-span-3 col-span-4">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Topics
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8">
                  <p className="font-sans md:pl-[77px] pl-[10px] font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Sub-topics
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Fundamental arithmetical operations
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Organization of a computer
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Central Processing Unit (CPU)
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Input/ output devices
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Computer memory
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Memory organization
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Back-up devices
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    PORTs
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Windows Explorer
                  </p>
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Keyboard shortcuts
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Software
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Windows Operating system including basics of Microsoft
                    Office like MS word, MS Excel and Power Point etc
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Working with the Internet and e-mails
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Web Browsing & Searching, Downloading & Uploading, Managing
                    an E-mail Account, e-Banking
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Basics of networking and cyber security
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Networking devices and protocols, Network and information
                    security threats (like hacking, virus, worms, Trojan etc.)
                    and preventive measures
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up">
            <div className="bg-[#fffcf9] md:pt-[110px] pt-[50px] pb-[20px]">
              <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[459px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
                SSC CGL Tier 2 Syllabus- Paper 2 (Statistics)
              </h6>
              <div className="bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px] gap-[24px]">
                <div className="flex flex-col gap-[20px]">
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    Paper 2 is only for those students who are applying for the
                    post of Junior Statistical Officer (JSO)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up">
            <div className="flex flex-col gap-[12px]">
              <div className="grid grid-cols-12 bg-[#F1F2F6] p-[19px]">
                <div className="md:col-span-3 col-span-4">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Topics
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8">
                  <p className="font-sans pl-[77px] font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Sub-topics
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    1. Collection, Classification and Presentation of
                    Statistical Data
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Primary and Secondary data, Methods of data collection;
                    Tabulation of data; Graphs and charts; Frequency
                    distributions; Diagrammatic presentation of frequency
                    distributions.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    2. Measures of Central Tendency 
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Common measures of central tendency – mean median and mode;
                    Partition values- quartiles, deciles, percentiles.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    3. Measures of Dispersion- Common measures of Dispersion
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    range, quartile deviations, mean deviation and standard
                    deviation; Measures of relative dispersion.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    4. Moments, Skewness and Kurtosis
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Different types of moments and their relationship; the
                    meaning of skewness and kurtosis; different measures of
                    skewness and kurtosis.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    5. Correlation and Regression
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Scatter diagram; simple correlation coefficient; simple
                    regression lines; Spearman‟s rank correlation; Measures of
                    association of attributes; Multiple regression; Multiple and
                    partial correlation (For three variables only).
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    6. Probability Theory
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Meaning of probability; Different definitions of
                    probability; Conditional probability; Compound probability;
                    Independent events; Bayes‟ theorem.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    7. Random Variable and Probability Distributions
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Random variable; Probability functions; Expectation and
                    Variance of a random variable; Higher moments of a random
                    variable; Binomial, Poisson, Normal and Exponential
                    distributions; Joint distribution of two random variable
                    (discrete).
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    8. Sampling Theory
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Concept of population and sample; Parameter and statistic,
                    Sampling and non-sampling errors; Probability and
                    nonprobability sampling techniques(simple random sampling,
                    stratified sampling, multistage sampling, multiphase
                    sampling, cluster sampling, systematic sampling, purposive
                    sampling, convenience sampling and quota sampling); Sampling
                    distribution(statement only); Sample size decisions.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    9. Statistical Inference
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Point estimation and interval estimation, Properties of a
                    good estimator, Methods of estimation (Moments method,
                    Maximum likelihood method, Least squares method), Testing of
                    hypothesis, Basic concept of testing, Small sample and large
                    sample tests, Tests based on Z, t, Chi-square and F
                    statistic, Confidence intervals.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    10. Analysis of Variance
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Analysis of one-way classified data and two-way classified
                    data.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    11. Time Series Analysis
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Components of time series, Determination of trend component
                    by different methods, Measurement of seasonal variation by
                    different methods.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                <div className="md:col-span-3 col-span-4 pl-[22px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    12. Index Numbers
                  </p>
                </div>
                <div className="md:col-span-9 col-span-8 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[77px] pl-[10px]">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Meaning of Index Numbers, Problems in the construction of
                    index numbers, Types of index number, Different formulae,
                    Base shifting and splicing of index numbers, Cost of living
                    Index Numbers, Uses of Index Numbers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#fffcf9] md:pt-[110px] pt-[50px] pb-[20px]">
            <div data-aos="fade-right">
              <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[735px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
                SSC CGL Tier 2 Syllabus- Paper 3(General Studies-Finance and
                Economics)
              </h6>
            </div>
            <div data-aos="fade-up">
              <div className="bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px] gap-[24px]">
                <div className="flex flex-col gap-[20px]">
                  <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                    Paper 3 is only for those students who are applying for the
                    post of Assistant Audit Officer/Assistant Account Officer.
                    (AAO)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up">
            <div className="flex flex-col gap-[12px]">
              <div className="grid grid-cols-12 bg-[#F1F2F6] p-[19px]">
                <div className="md:col-span-3 col-span-4">
                  <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Part
                  </p>
                </div>
                <div className="md:col-span-3 col-span-4">
                  <p className="font-sans md:pl-[40px] pl-[10px] font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Subject
                  </p>
                </div>
                <div className="md:col-span-6 col-span-4">
                  <p className="font-sans font-[600] md:pl-[40px] pl-[10px] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                    Topics
                  </p>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-12 items-center border-b-0 bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="md:col-span-3 col-span-4 md:pl-[22px] pl-[10px]">
                    <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                      Part A: Finance and Accounts-(80 marks)
                    </p>
                  </div>
                  <div className="md:col-span-9 col-span-8">
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Financial Accounting
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-4 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Nature and scope
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Limitations of Financial Accounting
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Basic concepts and Conventions
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            4. Generally Accepted Accounting Principles
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0]">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Basic concepts of accounting
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Single and double entry
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Books of Original Entry
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Bank Reconciliation
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            4. Journal, ledgers
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            5. Trial Balance
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            6. Rectification of Errors
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            7. Manufacturing
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            8. Trading
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            9. Profit & Loss Appropriation Accounts
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            10. Balance Sheet
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            11. Distinction between Capital and Revenue
                            Expenditure
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            12. Depreciation Accounting
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            13. Valuation of Inventories
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            14. Non-profit organisations Accounts
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            15. Receipts and Payments and Income & Expenditure
                            Accounts
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            16. Bills of Exchange
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            17. Self Balancing Ledgers
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 items-center bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="md:col-span-3 col-span-4 pl-[22px]">
                    <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                      Part B: Economics and Governance-(120 marks)
                    </p>
                  </div>
                  <div className="md:col-span-9 overflow-hidden col-span-8">
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <p className="font-sans py-[20px] md:pl-[37px] pl-[10px] font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                        Comptroller & Auditor General of India- Constitutional
                        provisions, Role and responsibility
                      </p>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <p className="font-sans py-[20px] md:pl-[37px] pl-[10px] font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                        Finance Commission-Role and functions
                      </p>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Basic Concept of Economics and introduction to Micro
                            Economics
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Definition
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Scope and nature of Economics
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Methods of economic study
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            4. Central problems of an economy
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            5. Production possibilities curve
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Theory of Demand and Supply
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Meaning and determinants of demand
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Law of demand and Elasticity of demand
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Price
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            4. Income and cross elasticity
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            5. Theory of consumer's behaviour
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            6. Marshallian approach and Indifference curve
                            approach
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            7. Meaning and determinants of supply
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            8. Law of supply
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            9. The elasticity of Supply
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Theory of Production and cost
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Meaning and Factors of production
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Laws of production- Law of variable proportions
                            and Laws of returns to scale.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Forms of Market and price determination in different
                            markets
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Various forms of markets-Perfect Competition
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Monopoly
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Monopolistic Competition
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            4. Oligopoly
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            5. Price determination in these markets.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-8 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Indian Economy
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Nature of the Indian Economy Role of different
                            sectors, Role of Agriculture, Industry and
                            Services-their problems and growth.
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. National Income of India-Concepts of national
                            income, Different methods of measuring national
                            income.
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Population-Its size, rate of growth and its
                            implication on economic growth.
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            4. Poverty and unemployment- Absolute and relative
                            poverty, types, causes and incidence of
                            unemployment.
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            5. Infrastructure-Energy, Transportation,
                            Communication.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Economic Reforms in India
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Economic reforms since 1991
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Liberalisation
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Privatisation
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            4. Globalisation
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            5. Disinvestment
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0] border-b">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Money and Banking
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            1. Monetary/ Fiscal policy- Role and functions of
                            Reserve Bank of India; functions of commercial
                            Banks/RRB/Payment Banks.
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            2. Budget and Fiscal deficits and Balance of
                            payments.
                          </p>
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            3. Fiscal Responsibility and Budget Management Act,
                            2003.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-9 col-span-8 border-l border-[#D2D0D0]">
                      <div className="grid grid-cols-12 items-center">
                        <div className="md:col-span-4 col-span-6 py-[20px] md:pl-[37px] pl-[10px]">
                          <p className="font-sans font-[600] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px]">
                            Role of Information Technology in Governance
                          </p>
                        </div>
                        <div className="md:col-span-8 col-span-6 py-[20px] border-l border-[#D2D0D0] flex flex-col gap-[20px] md:pl-[37px] pl-[10px]">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container m-auto">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="md:py-[72px] py-[30px] flex md:flex-row flex-col items-center justify-center gap-[16px]">
            <Link
              to={sscCGL?.link1}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-[22px] bg-[#D0021B] py-[4px] pr-[7px] pl-[20px] rounded-full font-[Outfit] font-[500] text-[16px] leading-[16.43px] text-[#FFFFFF]"
            >
              Purchase Courses <img src="assets/home/round-right.svg" alt="" />
            </Link>
            <a
              href={sscCGL?.link2}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-[22px] border border-[#D0021B] py-[4px] pr-[7px] pl-[20px] rounded-full font-[Outfit] font-[500] text-[16px] leading-[16.43px] text-[#D0021B]"
            >
              Download Details <img src="assets/video/download.svg" alt="" />
            </a>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default ExamCenter;
