import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

// const data = [
//   { id: 1 },
//   { id: 2 },
//   { id: 3 },
//   { id: 4 },
//   { id: 5 },
//   { id: 6 },
//   { id: 7 },
// ];

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/s-right.svg" alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/s-left.svg" alt="" />
    </div>
  );
}

function CourseSlider({ filteredCourses }) {
  const navigate = useNavigate();
  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    autoplay: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "20px",
          slidesToShow: 1,
        },
      },
    ],
  };

  // console.log(courses);
  function isValidUrl(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  console.log(filteredCourses);
  return (
    <>
      <div className="slider-container relative">
        {filteredCourses?.length > 1 ? (
          <>
            <Slider {...settings}>
              {filteredCourses?.map((e, i) => {
                return (
                  <>
                    <div className="course-card relative" key={e?.id}>
                      <img
                        src={e?.image}
                        className="absolute h-[100px] w-[100px] top-[-70px] left-[50%] translate-x-[-50%]"
                        alt=""
                      />
                      <div className="px-[12px] pb-[33px] pt-[56px]">
                        <h6 className="font-sans text-center font-[600] text-[18px] leading-[24px] pb-[13px] text-[#000000]">
                          {e?.title}
                        </h6>
                        <p className="text-[#616161] text-center h-[18px] font-[Outfit] font-[400] text-[16px] leading-[20px]">
                          {/* {e?.termsandcondition === "" ? "" : e?.termsandcondition} */}
                        </p>
                        <div className="border-[1px] my-[12px] border-[#0000004D]"></div>
                        <div
                          onClick={() => {
                            if (e?.courselink && isValidUrl(e.courselink)) {
                              window.open(e.courselink, "_blank");
                            } else {
                              navigate(`/courses/${e?.category}`);
                            }
                          }}
                          className="flex items-center justify-center w-full"
                        >
                          <Link className="text-[#1C2672] no-underline bg-[#087AFF17] rounded-[6px] py-[4px] px-[20px] cursor-pointer font-[Roboto] font-[500] text-[12px] leading-[14.06px]">
                            Get Details
                          </Link>
                          <img src="assets/home/right.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
            <div className="flex md:pt-[58px] pt-[54px] justify-center items-center">
              {/* <button
                onClick={() => navigate("https://live.winnersinstitute.in/courses?categoryId=")}
                className="font-sans font-[400] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px] md:py-[16px] py-[16px] md:px-[21px] px-[14px] border-[0.5px] hover:bg-transparent duration-300 ease-in md:bg-[#D0021B] bg-[#FFFFFF] border-[#D0021B] rounded-[10px] hover:text-[#D0021B] md:text-[#FFFFFF] text-[#D0021B]"
              >
                See All Courses
              </button> */}

              <button
                onClick={() => window.location.href = "https://live.winnersinstitute.in/courses?categoryId="}
                className="font-sans font-[400] md:text-[18px] text-[14px] md:leading-[24px] leading-[20px] md:py-[16px] py-[16px] md:px-[21px] px-[14px] border-[0.5px] hover:bg-transparent duration-300 ease-in md:bg-[#D0021B] bg-[#FFFFFF] border-[#D0021B] rounded-[10px] hover:text-[#D0021B] md:text-[#FFFFFF] text-[#D0021B]"
              >
                See All Courses
              </button>

            </div>
          </>
        ) : (
          <>
            {filteredCourses?.length === 0 ? (
              <p className="border-[1px] border-[#000000] rounded-[10px] px-[30px] py-[20px] font-sans font-[700] mb-0 text-[18px] leading-[24px] text-[#000000]">
                No Course Available!
              </p>
            ) : (
              <div className="grid md:grid-cols-3 grid-cols-1 justify-center gap-[20px]">
                {filteredCourses?.map((e, i) => {
                  return (
                    <>
                      <div className="course-card relative mx-auto" key={e?.id}>
                        <img
                          src={e?.image}
                          className="absolute h-[100px] w-[100px] top-[-70px] left-[50%] translate-x-[-50%]"
                          alt=""
                        />
                        <div className="px-[12px] pb-[33px] pt-[56px]">
                          <h6 className="font-sans text-center font-[600] text-[18px] leading-[24px] pb-[13px] text-[#000000]">
                            {e?.title}
                          </h6>
                          <p className="text-[#616161] text-center h-[18px] font-[Outfit] font-[400] text-[16px] leading-[20px]">
                            {e?.termsandcondition === "" ? "" : e?.termsandcondition}
                          </p>
                          <div className="border-[1px] my-[12px] border-[#0000004D]"></div>
                          <div
                            onClick={() => {
                              if (e?.courselink && isValidUrl(e.courselink)) {
                                window.open(e.courselink, "_blank");
                              } else {
                                navigate(`/courses/${e?.category}`);
                              }
                            }}
                            className="flex items-center justify-center w-full"
                          >
                            <Link className="text-[#1C2672] bg-[#087AFF17] no-underline rounded-[6px] py-[4px] px-[20px] cursor-pointer font-[Roboto] font-[500] text-[12px] leading-[14.06px]">
                              Get Details
                            </Link>
                            {/* <img src="assets/home/right.svg" alt="" /> */}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CourseSlider;
